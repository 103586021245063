import { merge } from 'lodash'
import { FieldError } from 'react-hook-form'

import { toServerDate } from './date'

export function transformUndefinedToNull<T>(val: T) {
  return val === undefined ? null : val
}

export function transformUndefinedToString<T>(val: T) {
  return val === undefined ? '' : val
}
export function transformNullToString<T>(val: T) {
  return val === null ? '' : val
}

export function sanitizeFormValue(value: string, inputType: string) {
  value = typeof value === 'string' ? value.trim() : value

  if (value === '') {
    return undefined
  }

  if (inputType === 'number') {
    return parseFloat(value)
  }

  return value
}

export interface FormErrors {
  [k: string]: FieldError | FormErrors
}

function isFieldError(error: any): error is FieldError {
  return typeof error === 'object' && 'type' in error
}

/**
 * react-hook-form errors are nested objects, which makes them hard to iterate
 * over. This helper flattens the nested errors object into a single-level
 * object, with the keys being the dot-separated path to the error for nested
 * field names.
 */
export function flattenFormErrors(errors: FormErrors, prefix?: string) {
  const flattenedErrors: FormErrors = {}

  Object.keys(errors).forEach((key) => {
    const path = prefix ? `${prefix}.${key}` : key
    const error = errors[key]

    if (isFieldError(error)) {
      flattenedErrors[path] = error
      return
    }

    merge(flattenedErrors, flattenFormErrors(error, path))
  })

  return flattenedErrors
}

type ReplaceNullWithUndefined<T> = null extends T
  ? Exclude<T, null> | undefined
  : T

type FormDefaultTransformedValue<T> = T extends Date
  ? string
  : T extends object
    ? FormDefaults<T>
    : T

type FormDefaults<T> = T extends object
  ? {
      [k in keyof T]: FormDefaultTransformedValue<
        ReplaceNullWithUndefined<T[k]>
      >
    }
  : never

/**
 * Given a model (e.g. an `Objective` / `Program`, etc.), returns a new object
 * where all `null` values are replaced with `undefined` and all `Date`s are
 * converted to the server string representation (yyyy-mm-dd). This is useful for
 * setting the default values of a form.
 */
export function modelToFormDefaults<T extends Record<string, unknown>>(
  model: T,
): FormDefaults<T> {
  return Object.fromEntries(
    Object.entries(model).map(([key, value]) => {
      if (value instanceof Date) {
        value = toServerDate(value)
      }

      return [key, value ?? undefined]
    }),
  ) as FormDefaults<T>
}
