import { LocalDataManagerInterface } from '@/offline/interfaces/LocalDataManagerInterface'
import {
  Stores,
  Indexes,
  OfflineEvent,
  offlineEventPayloadSchema,
} from '@/offline/models'
import { camelizeSchema } from '@helpers/object'

import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'

import SyncEventBusManager from './SyncEventBusManager'

const SEVEN_DAYS_IN_MILLIS = 7 * 24 * 60 * 60 * 1000
const EVENT_PAYLOAD_SCHEMA = camelizeSchema(offlineEventPayloadSchema)

export class DefaultLocalDataManager implements LocalDataManagerInterface {
  private readonly dbName: string
  private readonly dbVersion: number
  private readonly syncEventBusManager: SyncEventBusManagerInterface
  db: IDBDatabase | null = null

  constructor(dbName: string, dbVersion: number) {
    this.dbName = dbName
    this.dbVersion = dbVersion
    this.syncEventBusManager = new SyncEventBusManager()
  }

  /**
   * Initializes an IndexedDB database and handles version upgrades and schema changes.
   * Object stores should be added here.
   *
   * @returns A Promise that resolves true on successful initialization otherwise false.
   */
  async initDB(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!globalThis.indexedDB) {
        reject('indexedDB is not defined')
      }
      const request: IDBOpenDBRequest = globalThis.indexedDB.open(
        this.dbName,
        this.dbVersion,
      )
      request.onsuccess = () => {
        this.db = request.result
        resolve(true)
      }
      request.onerror = () => {
        reject('Error opening IndexedDB: ' + request.error)
      }
      // fires when you first try to open a DB at a given value of version
      request.onupgradeneeded = async () => {
        this.db = request.result
        const transaction = request.transaction
        let sessionOS = null
        let clientOS = null
        let iseOS = null
        let iseEventsOS = null
        let symmetricKeyOS = null
        let lastUpdatedAtOS = null

        if (!this.db.objectStoreNames.contains(Stores.Session)) {
          sessionOS = this.db.createObjectStore(Stores.Session, {
            keyPath: 'id',
          })
        } else {
          sessionOS = transaction?.objectStore(Stores.Session)
        }

        if (sessionOS && !sessionOS.indexNames.contains(Indexes.User)) {
          sessionOS.createIndex(Indexes.User, Indexes.User, { unique: false })
        }

        if (!this.db.objectStoreNames.contains(Stores.Client)) {
          clientOS = this.db.createObjectStore(Stores.Client, {
            keyPath: 'id',
          })
        } else {
          clientOS = transaction?.objectStore(Stores.Client)
        }

        if (clientOS && !clientOS.indexNames.contains(Indexes.User)) {
          clientOS.createIndex(Indexes.User, Indexes.User, { unique: false })
        }

        if (!this.db.objectStoreNames.contains(Stores.Ise)) {
          iseOS = this.db.createObjectStore(Stores.Ise, {
            keyPath: 'uuid',
          })
        } else {
          iseOS = transaction?.objectStore(Stores.Ise)
        }

        if (iseOS && !iseOS.indexNames.contains(Indexes.Uuid)) {
          iseOS.createIndex(Indexes.Uuid, Indexes.Uuid, { unique: false })
        }

        if (!this.db.objectStoreNames.contains(Stores.IseEvents)) {
          iseEventsOS = this.db.createObjectStore(Stores.IseEvents, {
            keyPath: 'uuid',
          })
        } else {
          iseEventsOS = transaction?.objectStore(Stores.IseEvents)
        }

        if (iseEventsOS && !iseEventsOS.indexNames.contains(Indexes.Uuid)) {
          iseEventsOS.createIndex(Indexes.Uuid, Indexes.Uuid, { unique: true })
        }

        if (!this.db.objectStoreNames.contains(Stores.SymmetricKey)) {
          symmetricKeyOS = this.db.createObjectStore(Stores.SymmetricKey, {
            keyPath: 'id',
          })
        } else {
          symmetricKeyOS = transaction?.objectStore(Stores.SymmetricKey)
        }

        if (symmetricKeyOS && !symmetricKeyOS.indexNames.contains(Indexes.Id)) {
          symmetricKeyOS.createIndex(Indexes.Id, Indexes.Id, { unique: true })
        }

        if (!this.db.objectStoreNames.contains(Stores.LastUpdatedAt)) {
          lastUpdatedAtOS = this.db.createObjectStore(Stores.LastUpdatedAt, {
            keyPath: 'id',
          })
        } else {
          lastUpdatedAtOS = transaction?.objectStore(Stores.LastUpdatedAt)
        }

        if (
          lastUpdatedAtOS &&
          !lastUpdatedAtOS.indexNames.contains(Indexes.Id)
        ) {
          lastUpdatedAtOS.createIndex(Indexes.Id, Indexes.Id, {
            unique: true,
          })
        }
      }
    })
  }

  /**
   * Create an index in an IndexedDB store if it doesn't exist.
   *
   * @param store - The IndexedDB object store in which the index should be created.
   * @param indexName - The name of the index.
   * @param keypath - The keypath value for the index.
   * @param unique - A boolean flag indicating whether the index should enforce uniqueness.
   *
   * @returns A `Promise` that resolves to `true` if the index is created or already exists, or rejects with `false` if an error occurs.
   */
  createIndex(
    store: IDBObjectStore,
    indexName: string,
    keypath: string,
    unique: boolean,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.db) {
        reject('createIndex: Database is not initialized')
      } else {
        // Create the index within the transaction.
        if (!this.db.objectStoreNames.contains(store.name)) {
          store.createIndex(indexName, keypath, { unique: unique })
          resolve(true)
        } else {
          reject('createIndex: Index already exists')
        }
      }
    })
  }

  /**
   * Retrieve data from an IndexedDB store using an index.
   *
   * @param storeName - The name of the IndexedDB store.
   * @param indexName - The name of the index within the store to query.
   * @param value - The value to look for in the specified index.
   * @returns A promise that resolves with an array of retrieved data or rejects with an error.
   */
  getDataByIndex<T>(
    storeName: string,
    indexName: string,
    value: string,
  ): Promise<T[] | null> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject('getDataByIndex: Database is not initialized')
      } else {
        const transaction = this.db.transaction(storeName, 'readonly')
        const store = transaction.objectStore(storeName)
        const index = store.index(indexName)
        const request = index.openCursor(IDBKeyRange.only(value))
        const result: T[] = []
        request.onsuccess = () => {
          const cursor = request.result
          if (cursor) {
            result.push(cursor.value)
            cursor.continue()
          } else {
            resolve(result)
          }
        }
        request.onerror = () => {
          reject(request.error)
        }
      }
    })
  }

  /**
   * Adds data to a specified object store in the IndexedDB.
   *
   * @param storeName - The name of the object store to add data to.
   * @param data - The data to be added, either a single item or an array of items.
   *
   * @returns A Promise that resolves with success or an error message as a string.
   */
  async addData<T>(storeName: string, data: T | T[]) {
    /* eslint-disable no-async-promise-executor */
    return new Promise<T | T[] | string>(async (resolve, reject) => {
      if (!this.db) {
        reject('Database is not initialized')
      } else {
        const transaction = this.db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        if (Array.isArray(data)) {
          const requests = data.map((item) => store.put(item))
          const results = await Promise.all(
            requests.map((request) => {
              return new Promise((resolve) => {
                request.onsuccess = () => resolve(true)
                request.onerror = () => {
                  reject('Error adding data to the store')
                }
              })
            }),
          )
          if (results.every((result) => result)) {
            resolve('Data added successfully')
          } else {
            reject('Error adding data to the store')
          }
        } else {
          const request = store.put(data)
          request.onsuccess = () => resolve('Data added successfully')
          request.onerror = () => reject('Error adding data to the store')
        }
      }
    })
  }

  /**
   * Deletes data from an IndexedDB store.
   *
   * @param storeName - The name of the store from which to delete data.
   * @param keys - A single key or an array of keys to delete.
   *
   * @returns A `Promise` that resolves with a success message or rejects with an error message.
   */
  async deleteData(storeName: string, keys: string | string[]) {
    return new Promise<number | string>(async (resolve, reject) => {
      if (!this.db) {
        reject('Database is not initialized')
      } else {
        const transaction = this.db.transaction(storeName, 'readwrite')
        const store: IDBObjectStore = transaction.objectStore(storeName)

        if (Array.isArray(keys)) {
          const deletePromises: Promise<void | string>[] = keys.map(
            (key: string) =>
              new Promise((resolve, reject) => {
                const request: IDBRequest = store.delete(key)
                request.onsuccess = () => resolve('Data deleted successfully')
                request.onerror = () => reject('Error deleting data')
              }),
          )

          await Promise.all(deletePromises)
          resolve('Data deleted successfully')
        } else {
          const request = store.delete(keys)
          request.onsuccess = () => resolve('Data deleted successfully')
          request.onerror = () => {
            reject('Error deleting data')
          }
        }
      }
    })
  }

  /**
   * Updates data in an IndexedDB store.
   *
   * @param storeName - The name of the store where data should be updated.
   * @param data - An object or an array of objects to update.
   *
   * @returns A `Promise` that resolves with the number of items updated, or rejects with an error message.
   */
  async updateData<T>(
    storeName: string,
    data: T | T[],
  ): Promise<number | string> {
    return new Promise<number | string>(async (resolve, reject) => {
      if (!this.db) {
        reject('Database is not initialized')
      } else {
        const transaction = this.db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)

        if (Array.isArray(data)) {
          const promises = data.map((item: T) => store.put(item))
          const results = await Promise.all(promises)
          resolve(results.length)
        } else {
          console.log('dbw data', data)
          store.put(data)
          resolve(1)
        }
      }
    })
  }

  /**
   * Retrieves data from an IndexedDB store.
   *
   * @param storeName - The name of the store from which data should be retrieved.
   * @param keys - An array of primary keys to retrieve specific items (optional).
   *
   * @returns A `Promise` that resolves with the retrieved data, an array of data, a single data item, or rejects with an error message.
   */
  async getStoreData<T>(storeName: string, keys?: string[]): Promise<T[]> {
    return new Promise<T[]>(async (resolve, reject) => {
      if (!this.db) {
        reject('Database is not initialized')
      } else {
        const transaction = this.db.transaction(storeName, 'readonly')
        const store = transaction.objectStore(storeName)

        if (keys && keys.length > 0) {
          const promises = keys.map(
            (key) =>
              new Promise((res, rej) => {
                const request = store.get(key)
                request.onsuccess = () => res(request.result)
                request.onerror = () => {
                  rej(request.error)
                }
              }),
          )

          const results = await Promise.all(promises)

          // dbw 5/22/2024: this is a hack to get around the fact that we can't
          // return an array of T[] from a promise.
          resolve(results as T[])
        } else {
          const request = store.getAll()
          request.onsuccess = () => resolve(request.result)
          request.onerror = () => {
            reject(request.error)
          }
        }
      }
    })
  }

  /**
   * Clears all object stores in the IndexedDB database.
   *
   * @returns A promise that resolves to `true` if all object stores were cleared successfully,
   *          or `false` if an error occurred during the clearing process.
   */
  async clearDB(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!this.db) {
        reject('Database is not initialized')
      } else {
        const objectStoreNames = Array.from(this.db.objectStoreNames)

        if (objectStoreNames.length === 0) {
          resolve(true) // No object stores to clear
        }

        const clearPromises = objectStoreNames.map((storeName: string) => {
          return new Promise<boolean>((resolve, reject) => {
            const transaction = (this.db as IDBDatabase).transaction(
              storeName,
              'readwrite',
            )
            const store = transaction.objectStore(storeName)
            const clearRequest = store.clear()

            clearRequest.onsuccess = () => resolve(true)
            clearRequest.onerror = () => {
              reject(`Error clearing DB: ${clearRequest.error}`)
            }

            transaction.oncomplete = () => resolve(true)
            transaction.onerror = () => {
              reject(`Error clearing object store: ${transaction.error}`)
            }
          })
        })

        const allClear = await Promise.all(clearPromises)
        resolve(allClear.every((result: boolean) => result))
      }
    })
  }

  /**
   * Deletes the IndexedDB database, permanently removing it.
   *
   * @returns A promise that resolves to `true` if the database was deleted successfully, or `false` on failure.
   */
  async deleteDB(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!this.db) {
        reject('Database is not initialized')
      } else {
        this.db.close()

        const request = globalThis.indexedDB.deleteDatabase(this.db.name)

        request.onsuccess = () => {
          resolve(true)
        }

        request.onerror = () => {
          reject(`Error deleting database: ${request.error}`)
        }
      }
    })
  }

  /**
   * Closes the IndexedDB database. If the database is successfully closed, this method
   * resolves to `true`. If there is an error while closing the database, it resolves to `false`.
   *
   * @returns A promise that resolves to `true` on successful closure or `false` on error.
   */
  async closeDB(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (this.db) {
          this.db.close()
          this.db = null // Reset the reference to the database

          // other cleanup or post-closing actions can go here

          resolve(true)
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(`Error closing database: ${error}`)
      }
    })
  }

  async removeQueuedData() {
    if (!this.db) {
      throw new Error('Database is not initialized')
    }

    const queuedDataStores = [Stores.IseEvents, Stores.Ise]
    queuedDataStores.map(async (storeName) => {
      const transaction = this.db?.transaction(storeName, 'readwrite')
      const store = transaction?.objectStore(storeName)
      store?.clear()
    })
  }

  private plusSevenDays(date: Date) {
    return new Date(date.getTime() + SEVEN_DAYS_IN_MILLIS)
  }

  async drainQueue({ csrfToken = '' }) {
    this.syncEventBusManager.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.START, {
        detail: { message: 'draining queued events' },
      }),
    )
    const promises = [Stores.IseEvents, Stores.Ise].map(async (objectStore) => {
      const data = await this.getStoreData(objectStore)
      return [objectStore, data]
    })
    const entries = await Promise.all(promises)
    const payload = Object.fromEntries(entries)

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('x-csrftoken', csrfToken)
    const response = await fetch(`/api/offline/offline-session-events/`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers,
    })

    if (response.ok) {
      this.removeQueuedData()
    } else {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { message: 'Failed to drain queue' },
        }),
      )
    }
  }

  async removeExpiredData() {
    const now = new Date()
    const dataStoresToExpire = [Stores.IseEvents, Stores.Ise]

    dataStoresToExpire.map(async (storeName) => {
      if (!this.db) {
        throw new Error('Database is not initialized')
      }

      const transaction = this.db.transaction(storeName, 'readwrite')
      const store = transaction.objectStore(storeName)
      const request = store.openCursor()

      // Silently fail
      request.onerror = function (event) {
        // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/30669
        console.error('Error removing expired data:', event.target?.error)
      }

      request.onsuccess = (event) => {
        // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/30669
        const cursor: IDBCursorWithValue = event.target?.result
        if (cursor) {
          const { timestamp } = cursor.value
          if (timestamp && this.plusSevenDays(timestamp) < now) {
            store?.delete(cursor.key)
          }
          cursor.continue()
        }
      }
    })
  }

  async hasQueuedEvents(sessionId: string): Promise<boolean> {
    const queuedEvents = await this.getStoreData<OfflineEvent>(Stores.IseEvents)
    if (!queuedEvents) {
      return false
    }

    return queuedEvents.some((event) => event.sessionId === sessionId)
  }

  async getQueuedEvents() {
    const iseEvents = await this.getStoreData<OfflineEvent>(Stores.IseEvents)
    const ise = await this.getStoreData(Stores.Ise)
    const endEvents = iseEvents.filter((event) => {
      if (!event.event) {
        return false
      }

      const parsedJson = JSON.parse(event.event)
      const camelizedJson = EVENT_PAYLOAD_SCHEMA.parse(parsedJson)
      return camelizedJson.eventType === 'stop'
    })
    const endedSessions = new Set(endEvents.map((event) => event.sessionId))
    const queuedEvents = iseEvents.filter((event) =>
      endedSessions.has(event.sessionId),
    )

    return { iseEvents: queuedEvents, ise }
  }

  async getLastUpdatedAt(): Promise<number> {
    const ise = await this.getStoreData<{ timestamp: number }>(
      Stores.LastUpdatedAt,
    )
    return ise[0]?.timestamp
  }

  async setLastUpdatedAt(timestamp: number) {
    console.log('setting last updated at', timestamp)
    await this.updateData(Stores.LastUpdatedAt, {
      id: 1, // always use 1 so we overwrite the old key when we get a new one
      timestamp,
    })
  }
}
