export const config = {
  env: process.env.NODE_ENV,
  i18nVersions: process.env.LOCALE_VERSIONS,
  apiRequestTimeout: 50000,
  MAX_TTL_MILLIS: 1000 * 60 * 30,
  TTL: 'TTL',
  swURL: '/sw.js',
  DB_NAME: 'offline_twyll',
  DB_VERSION: 3,
  URL_BLOCKLIST: ['/accounts/login/'],
  symmKey: 'symmKey',
  SYNC_INTERVAL: 1000 * 60 * 60, // one hour in seconds
} as const
