import { Dispatch, SetStateAction } from 'react'

class HeartbeatManager {
  private _observers: Dispatch<SetStateAction<boolean>>[] = []
  private _isOnline = navigator.onLine

  constructor(isOnline: boolean) {
    this._isOnline = isOnline

    setInterval(() => {
      this.ping()
    }, 5000)
  }

  observe(update: Dispatch<SetStateAction<boolean>>) {
    this._observers.push(update)
    update(this._isOnline)
  }

  get isOnline(): boolean {
    return this._isOnline
  }

  removeObserver(update: Dispatch<SetStateAction<boolean>>) {
    this._observers = this._observers.filter((observer) => observer !== update)
  }

  private setIsOnline(isOnline: boolean) {
    this._isOnline = isOnline
    this._observers.forEach((update) => update(isOnline))
  }

  private ping() {
    // DBW 8/1/2024: We get false positives, but not false negatives, from navigator.onLine,
    // per MDN, so we can safely bomb out when navigator.onLine is false.
    if (!navigator.onLine) {
      this.setIsOnline(false)
      return
    }

    fetch('/ping/')
      .then(() => {
        this.setIsOnline(true)
      })
      .catch(() => {
        this.setIsOnline(false)
      })
  }
}

export default new HeartbeatManager(navigator.onLine)
